import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Section } from "../../layout";
import { Gallery, Thumb, DesktopRightImage, PhoneImage } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`We put in lot of work to make Trailguide better, faster and easier to use.
Thanks to the feedback of many users, we could improve the usability. To get the
new version, delete the icon on your homescreen (if you have one), go to
trailguide.net in Chrome (Android) or Safari (IOS). Refresh one or two times to
get the latest code. See here what has changed:`}</p>
    <h2>{`1) Install Trailguide on your phone`}</h2>
    <h4>{`Progressive Web App`}</h4>
    <p>{`Trailguide can be installed as an app on your phone. It is a PWA - a
Progressive Web App. When you open trailguide.net in your browser, a banner
pops up and suggests to add Trailguide to your home screen. Install it and
benefit from all functions of a PWA including offline usability. Click on the
thumbnails to open bigger pictures:`}</p>
    <br />
    <Gallery mdxType="Gallery">
  <Thumb src="news/v2-addtohomescreen.jpg" mdxType="Thumb">Installation banner</Thumb>
  <Thumb src="news/v2-iconhomescreen.jpg" mdxType="Thumb">
    Start Trailguide from the home screen.
  </Thumb>
  <Thumb src="news/v2-startscreen.jpg" mdxType="Thumb">
    Trailguide started as an app. The URL line disappears.
  </Thumb>
  <Thumb src="news/v2-appinfo.jpg" mdxType="Thumb">
    See all information about the app in your system."
  </Thumb>
    </Gallery>
    <br />
    <Section mdxType="Section" />
    <DesktopRightImage src="news/v2-sharetrails2.jpg" mdxType="DesktopRightImage" />
    <h2>{`2) Share trails`}</h2>
    <h4>{`Built in sharing function`}</h4>
    <p>{`When Trailguide is installed, you can easily share the trail you have opend.
Swipe down from the top of your screen and click on copy URL, or directly share
it in another app.`}</p>
    <PhoneImage src="news/v2-sharetrails2.jpg" mdxType="PhoneImage" />
    <Section mdxType="Section" />
    <DesktopRightImage src="news/v2-conditions2.jpg" mdxType="DesktopRightImage" />
    <h2>{`3) Report conditions`}</h2>
    <h4>{`Feedback`}</h4>
    <p>{`Beside reviewing a trail, you can also report conditions. You don't need to be
on the trail anymore!`}</p>
    <PhoneImage src="news/v2-conditions2.jpg" mdxType="PhoneImage" />
    <Section mdxType="Section" />
    <DesktopRightImage src="news/v2-download2.jpg" mdxType="DesktopRightImage" />
    <h2>{`4) Free download`}</h2>
    <h4>{`No log in needed`}</h4>
    <p>{`Download the GPS track without beeing logged in!`}</p>
    <PhoneImage src="news/v2-download2.jpg" mdxType="PhoneImage" />
    <Section mdxType="Section" />
    <DesktopRightImage src="news/v2-traileditorheightprofile.jpg" mdxType="DesktopRightImage" />
    <h2>{`5) Trail Editor`}</h2>
    <h4>{`Height profile`}</h4>
    <p>{`Uploading a trail, you can cut your recorded track easily with the slider below
the map. In addition you now have the hightprofile, to be able to cut the
track more precisely.`}</p>
    <PhoneImage src="news/v2-traileditorheightprofile.jpg" mdxType="PhoneImage" />
    <Section mdxType="Section" />
    <DesktopRightImage src="news/v2-traileditorcopytrail.jpg" mdxType="DesktopRightImage" />
    <h2>{`6) Trail Editor`}</h2>
    <h4>{`Copy a trail`}</h4>
    <p>{`You can easily copy a trail that you uploaded. Only import your track once
and quickly create multiple trails out of this one track. You can also use
it to upload the loop or access as a hidden track first. Then save the
singletrack as a public trail and copy the URL of the loop into the
description of the singletrack to connect both.`}</p>
    <PhoneImage src="news/v2-traileditorcopytrail.jpg" mdxType="PhoneImage" />
    <Section mdxType="Section" />
    <DesktopRightImage src="news/v2-dashboard.jpg" mdxType="DesktopRightImage" />
    <h2>{`7) User Page`}</h2>
    <h4>{`Overview over your content`}</h4>
    <p>{`The user page shows your trails, reviews and condition reports. In addition
you have a dasboard with an overview over your content.`}</p>
    <PhoneImage src="news/v2-dashboard.jpg" mdxType="PhoneImage" />
    <Section mdxType="Section" />
    <DesktopRightImage src="news/v2-localservices2.jpg" href="/news/adsforlocalservices" mdxType="DesktopRightImage" />
    <h2>{`8) Local Services`}</h2>
    <h4>{`Find the best services`}</h4>
    <p>{`Need a guide or shuttle, a bike friendly accomodation, or just want to know
the best place for a beer after riding? Now you can find the best local
services on Trailguide. Do you own a local service? Find out more how you can
make it visible on Trailguide
`}<a parentName="p" {...{
        "href": "/news/adsforlocalservices"
      }}>{`here`}</a></p>
    <PhoneImage src="news/v2-localservices2.jpg" href="/news/adsforlocalservices" mdxType="PhoneImage" />
    <Section mdxType="Section" />
    <DesktopRightImage src="news/userawards0619.jpg" href="/news/norronauserawards" mdxType="DesktopRightImage" />
    <h2>{`Win great prizes from Norrøna!`}</h2>
    <p>{`Upload your trails on trailguide.net until the 15th of August and win great
prizes from Norrøna! Find out more on our `}<a parentName="p" {...{
        "href": "/news/norronauserawards"
      }}>{`website`}</a>{`.`}</p>
    <PhoneImage src="news/userawards0619.jpg" href="/news/norronauserawards" mdxType="PhoneImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      